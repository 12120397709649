@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .pause {
        animation-play-state: paused;
    }
}

*:focus {
    outline: none;
}

*:after {
    color: #a12003
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body {
    padding: 0;
    margin: 0;
    color: white;
    font-family: 'Lato', sans-serif;
}

input::placeholder {
    color: #2a303b
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    color: #fff;
    transition: background-color 999999s ease-in-out 0s;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.835)
}